<template>
  <div>
    <div>
      <el-date-picker
          v-model="selectedDate"
          type="date"
          placeholder="选择日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          @change="handlePartChange"
      />
      <el-button type="primary" @click="setShift" style="margin-left: 10px">排班</el-button>
    </div>
    <el-row :gutter="20">
      <!-- 早班维修工 -->
      <el-col :span="24">
        <el-card>
          <h3>早班维修工</h3>
          <el-table :data="morningShift" border stripe>
            <el-table-column prop="id" label="ID" v-if="false"></el-table-column>
            <el-table-column prop="nickname" label="昵称"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <el-tag :type="getTagType(scope.row.state)" size="medium">{{ scope.row.state }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="维修工单类型">
              <template v-slot="scope">
                <span>{{ getTypes(scope.row.id)  }}</span>
              </template>
            </el-table-column>
            <el-table-column label="今日工时">
            <template v-slot="scope">
              <span>{{ formatTime(scope.row.dailyHour)  }}</span>
            </template>
            </el-table-column>
            <el-table-column prop="phone" label="手机号"></el-table-column>
            <el-table-column label="小组">
              <template v-slot="scope">
                <span>{{ groups.find(v => v.id === scope.row.groupId) ? groups.find(v => v.id === scope.row.groupId).name : ''  }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>

      <!-- 中班维修工 -->
      <el-col :span="24">
        <el-card>
          <h3>中班维修工</h3>
          <el-table :data="afternoonShift" border stripe>
            <el-table-column prop="id" label="ID" v-if="false"></el-table-column>
            <el-table-column prop="nickname" label="昵称"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <el-tag :type="getTagType(scope.row.state)" size="medium">{{ scope.row.state }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="维修工单类型">
              <template v-slot="scope">
                <span>{{ getTypes(scope.row.id)  }}</span>
              </template>
            </el-table-column>
            <el-table-column label="今日工时">
              <template v-slot="scope">
                <span>{{ formatTime(scope.row.dailyHour)  }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="phone" label="手机号"></el-table-column>
            <el-table-column label="小组">
              <template v-slot="scope">
                <span>{{ groups.find(v => v.id === scope.row.groupId) ? groups.find(v => v.id === scope.row.groupId).name : ''  }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>

      <!-- 晚班维修工 -->
      <el-col :span="24">
        <el-card>
          <h3>晚班维修工</h3>
          <el-table :data="eveningShift" border stripe>
            <el-table-column prop="id" label="ID" v-if="false"></el-table-column>
            <el-table-column prop="nickname" label="昵称"></el-table-column>
            <el-table-column label="状态">
              <template v-slot="scope">
                <el-tag :type="getTagType(scope.row.state)" size="medium">{{ scope.row.state }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="维修工单类型">
              <template v-slot="scope">
                <span>{{ getTypes(scope.row.id)  }}</span>
              </template>
            </el-table-column>
            <el-table-column label="今日工时">
              <template v-slot="scope">
                <span>{{ formatTime(scope.row.dailyHour)  }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="phone" label="手机号"></el-table-column>
            <el-table-column label="小组">
              <template v-slot="scope">
                <span>{{ groups.find(v => v.id === scope.row.groupId) ? groups.find(v => v.id === scope.row.groupId).name : ''  }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

    <el-dialog title="排班" :visible.sync="dialogFormVisible" width="95%" :close-on-click-modal="false">
      <div style="display: flex; gap: 20px;">
        <!-- 左边部分 -->
        <el-card class="box-card" style="flex: 2;">
          <el-calendar v-loading="loading" v-model="date">
            <template slot="dateCell" slot-scope="{date, data }">
              <div>
                <el-row>
                  <el-col :span="6">
                    <div class="solar">
                      {{ data.day.split('-')[2] }}
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="lunar" :class="{ festival : isFestival(date, data) }">{{ solarDate2lunar(data.day) }}</div>
                  </el-col>
                  <el-col :span="6">
                    <el-tag @click="handleDateClick(date, data)" effect="dark">选</el-tag>
                  </el-col>
                </el-row>
              </div>
            </template>
          </el-calendar>
        </el-card>
        <!-- 右边部分 -->
        <el-card class="box-card" style="flex: 1; font-size: 30px;">
          <el-form label-width="150px" size="large">
            <el-form-item label="日期" prop="date">
              <el-date-picker
                  type="dates"
                  v-model="form.selectedDates"
                  placeholder="选择一个或多个日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="班次">
              <el-select clearable v-model="form.shift" placeholder="请选择班次">
                <el-option label="早班" value="早班"></el-option>
                <el-option label="中班" value="中班"></el-option>
                <el-option label="晚班" value="晚班"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="维修工">
              <el-select v-model="form.selectedUsers" multiple filterable placeholder="请选择">
                <el-option
                    v-for="item in maintainers"
                    :key="item.id"
                    :label="item.nickname"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
<!--            <el-form-item label="轮购工程师" v-if="value">-->
<!--              <el-select v-model="form.selectedUsers" multiple filterable placeholder="请选择">-->
<!--                <el-option-->
<!--                    v-for="item in users"-->
<!--                    :key="item.id"-->
<!--                    :label="item.nickname"-->
<!--                    :value="item.id">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
            <el-form-item>
              <el-button type="primary" @click="handleShift">提交</el-button>
            </el-form-item>
          </el-form>
          <el-divider></el-divider>
          <el-form label-width="120px" size="large">
            <el-form-item label="时间">
              <span>{{ maintainershift.workDate }}</span>
            </el-form-item>
            <el-form-item label="班次">
              <span>{{ maintainershift.shift }}</span>
            </el-form-item>
            <el-form-item label="维修工">
              <template v-slot="scope">
                <span>{{ getMaintainerNames(maintainershift.workDate)  }}</span>
              </template>
            </el-form-item>
<!--            <el-form-item label="轮购工程师">-->
<!--              <template v-slot="scope">-->
<!--                <span>{{ getEngNames(maintainershift.workDate)  }}</span>-->
<!--              </template>-->
<!--            </el-form-item>-->
          </el-form>
        </el-card>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import calendar from '@/utils/calendar';

export default {
  name: "MaintainerShift",
  data() {
    return {
      morningShift: [],
      afternoonShift: [],
      eveningShift: [],
      allMaintainers: [],
      groups: [],
      dialogFormVisible: false,
      users: [],
      maintainers: [],
      form: {},
      selectedDate: '',
      maintainershifts: [],
      maintainershift: {},
      failureRecords: [],
      value: false,
      today: this.formatDate(new Date()),
      loading: false,
      date: new Date(),
      holidayList:[],//假日
      workdayList:[],//工作日
      selectedType:null,
      calendarDayList:[
      ],
    };
  },
  created() {
    this.load();
    this.loadShiftData();
  },
  methods: {
    load() {
      this.request.get("/user/listExceptMaintainer").then(res => {
        this.users = res.data;
      });
      this.request.get("/maintainerShift").then(res => {
        this.maintainershifts = res.data;
      });
      this.request.get("/failureRecord").then(res => {
        this.failureRecords = res.data;
      });
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    setShift(){
      this.dialogFormVisible = true;
    },
    loadShiftData() {
      try {
        this.request.get("/user/listMaintainer").then(res => {
          this.maintainers = res.data;
          this.morningShift = this.maintainers.filter(worker => worker.workTime === '早班');
          this.afternoonShift = this.maintainers.filter(worker => worker.workTime === '中班');
          this.eveningShift = this.maintainers.filter(worker => worker.workTime === '晚班');
        })
      } catch (error) {
        this.$message.error("加载班次数据失败");
        console.error('Error loading shift data:', error);
      }
      this.request.get("/mtgroup").then(res => {
        this.groups = res.data
      })
    },
    getTagType(state) {
      // 根据状态返回不同的标签类型（颜色）
      switch (state) {
        case '维修中':
          return 'warning';
        case '休假中':
          return 'info';
        case '空闲中':
          return 'success';
      }
    },
    getTypes(userId) {
      const recordUsers = this.failureRecords.filter(item => item.state !== "作业结束" && item.state !== "工单关闭");
      return recordUsers.map(item => {
        const record = this.failureRecords.find(u => u.userId === userId);
        console.log(record ? record.reconum : '')
        return record ? record.failureType : '';
      }).join(', ');
    },
    formatTime(hours) {
      // 将小时数格式化为天时分秒的字符串显示
      const remainingHours = Math.floor(hours % 24);
      const minutes = Math.floor((hours * 60) % 60);
      return `${remainingHours}小时${minutes}分钟`;
    },
    handleShift() {
      this.request.post("/maintainerShift/setShift", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("修改成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getMaintainerNames(workDate) {
      const recomt = this.maintainershifts.filter(item => item.workDate === workDate);
      return recomt.map(item => {
        const maintainer = this.maintainers.find(u => u.id === item.userId);
        return maintainer ? maintainer.nickname : item.userId;
      }).join(', ');
    },
    // getEngNames(workDate) {
    //   const recomt = this.maintainershifts.filter(item => item.workDate === workDate);
    //   return recomt.map(item => {
    //     const engineer = this.users.find(u => u.id === item.userId);
    //     return engineer ? engineer.nickname : "";
    //   }).join('');
    // },
    // handleDateClick(date) {
    //   this.selectedDate = this.formatDate(date);
    //   console.log(this.selectedDate)
    //   this.maintainershift = this.maintainershifts.find(item => item.workDate === this.selectedDate) ? this.maintainershifts.find(item => item.workDate === this.selectedDate) : {};
    // },
    handleDateClick(date) {
      const selectedDate = this.formatDate(date);
      console.log("Clicked date:", this.formatDate(date));
      this.maintainershift = this.maintainershifts.find(item => item.workDate === selectedDate) ? this.maintainershifts.find(item => item.workDate === selectedDate) : {};
    },
    handlePartChange(){
      console.log(this.selectedDate)
      this.request.get("/maintainerShift/listMaintainer/"+this.selectedDate).then(res => {
        this.allMaintainers = res.data;
        this.morningShift = this.allMaintainers.filter(worker => worker.shift === '早班' && worker.userId === this.maintainers.id);
        this.afternoonShift = this.allMaintainers.filter(worker => worker.shift === '中班' && worker.userId === this.maintainers.id);
        this.eveningShift = this.allMaintainers.filter(worker => worker.shift === '晚班' && worker.userId === this.maintainers.id);
      })
    },
    isFestival(slotDate, slotData) {
      let solarDayArr = slotData.day.split('-');
      let lunarDay = calendar.solar2lunar(solarDayArr[0], solarDayArr[1], solarDayArr[2])

      // 公历节日\农历节日\农历节气
      let festAndTerm = [];
      festAndTerm.push(lunarDay.festival == null ? '' : ' ' + lunarDay.festival)
      festAndTerm.push(lunarDay.lunarFestival == null ? '' : '' + lunarDay.lunarFestival)
      festAndTerm.push(lunarDay.Term == null ? '' : '' + lunarDay.Term)
      festAndTerm = festAndTerm.join('')

      return festAndTerm !== ''
    },
    solarDate2lunar(solarDate) {
      var solar = solarDate.split('-')
      var lunar = calendar.solar2lunar(solar[0], solar[1], solar[2])

      let lunarMD = lunar.IMonthCn + lunar.IDayCn;
      // 公历节日\农历节日\农历节气
      let festAndTerm = [];
      festAndTerm.push(lunar.festival == null ? '' : ' ' + lunar.festival)
      festAndTerm.push(lunar.lunarFestival == null ? '' : '' + lunar.lunarFestival)
      festAndTerm.push(lunar.Term == null ? '' : '' + lunar.Term)
      festAndTerm = festAndTerm.join('')

      return festAndTerm === '' ? lunarMD : festAndTerm

    }
  }
}
</script>

<style scoped>
.el-card {
  margin-bottom: 20px;
}
.is-selected {
  color: #1989FA;
}
</style>
